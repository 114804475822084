import React, { useState, FunctionComponent } from 'react';
import { Collapse } from 'reactstrap';
// Components
import Image from '@Components/Utils/Image';
import Anchor from '@Components/Utils/Anchor';
import ComingSoon from '@Pages/gioi-thieu/Components/ComingSoon';
import LayoutContainer from '@Components/Layout/Components/Container';
import { useViewDetected, ViewMedia } from '@Components/Utils/ViewDetected';

// Icons

// Utilities
import classnames from 'classnames';

// Styles
import styles from './Header.module.scss';
import { useRouter } from 'next/router';
import URLRedirect from '@Config/urlRedirect';

const LINKS = [
  {
    href: '/gioi-thieu/phong-kham',
    name: 'Dành cho bác sĩ',
  },
  {
    href: '/gioi-thieu/nguoi-dung',
    name: 'Dành cho người dùng',
  },
];

interface HeaderProps {
  pathname?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ pathname }) => {
  const { deviceType } = useViewDetected();
  const router = useRouter()
  console.log('**** cc router', router.pathname);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const className = classnames([styles.wrapper, styles[deviceType]]);

  const toggleMenu = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const openModal = () => {
    setIsOpen(false);
    setIsOpenModal(true);
  };

  const renderLink = () => {
    return LINKS.map((link, index) => {
      const isActive = pathname.includes(link.href);

      return (
        <Anchor
          key={index}
          href={link.href}
          onClick={toggleMenu}
          className={classnames([
            styles.item,
            {
              [styles.active]: isActive,
            },
          ])}>
          {link.name}
        </Anchor>
      );
    });
  };

  return (
    <div className={className}>
      <LayoutContainer className={styles.container}>
        <Anchor href="/gioi-thieu/nguoi-dung">
          <Image src="/assets/default/logo.png" className={styles.logo} width={'217px'} height={'36px'} />
        </Anchor>

        <ViewMedia is="desktop">
          <div className={styles.right}>
            {renderLink()}
            {router.pathname.includes('/gioi-thieu/nguoi-dung') &&
              <a target="_blank" href={URLRedirect()?.eClinic} rel="noreferrer">
                <button className={styles.btn_login}>Đăng ký / Đăng nhập</button>
              </a>
            }
          </div>
        </ViewMedia>

        <ViewMedia is={['mobile', 'tablet']}>
          <div onClick={toggleMenu} className={classnames([styles.icon_toggle, { [styles.open]: isOpen }])} />

          <Collapse isOpen={isOpen} className={styles.collapse}>
            <div className={styles.menu}>
              {renderLink()}

              <div className={styles.btn_wrapper} onClick={openModal}>
                <button className={styles.btn_login}>Đăng ký / Đăng nhập</button>
              </div>
            </div>
          </Collapse>
        </ViewMedia>
      </LayoutContainer>

      <ComingSoon isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </div>
  );
};

export default Header;

import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

// Components
import { useViewDetected } from '@Components/Utils/ViewDetected';
import LayoutContainer from '@Components/Layout/Components/Container';

// Styles
import styles from './TopFooter.module.scss';

const TopFooter: FunctionComponent = () => {
  const { deviceType } = useViewDetected();
  return (
    <div className={classnames([styles.wrapper, styles[deviceType]])}>
      <LayoutContainer className={styles.main}>
        <h3 className={styles.title}>ĐƯỢC BẢO TRỢ THÔNG TIN BỞI</h3>
        <div className={styles.icon_google} />
        <div className={styles.icon_facebook} />
        <div className={styles.hint}>eDoctor - Công ty công nghệ cung cấp giải pháp y tế, duy nhất tại Việt Nam được tham gia Google Launchpad và Facebook Start</div>
      </LayoutContainer>
    </div>
  );
};

export default TopFooter;

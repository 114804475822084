import React from 'react';
import App, { AppContext } from 'next/app';

// Components
import Head from 'next/head';
import Alert from '@Components/Alert';
import Layout from '@Components/Layout';
import { CorporateContactJsonLd, NextSeo } from 'next-seo';

// 3rds
import LazyloadScripts from '@Components/Utils/Lazy/Script';

// Utilitiess
import Cookie from 'cookie';
import MobileDetect from 'mobile-detect';
import { registerServiceWorker } from '@Libs/utils';

// Contexts
import ViewDetected, { getDeviceType } from '@Components/Utils/ViewDetected';

// Config
import seo from '@Config/seo';
import host from '@Config/host';
import analytics from '@Config/analytics';

// Styles
import '@Styles/globals.scss';

function MyApp({ Component, pageProps, deviceType, disableFooter, disableHeader, pathname }) {
  registerServiceWorker();

  return (
    <>
      <Head>
        <meta name="copyright" content="eDoctor" />
        <meta name="theme-color" content="#248eff" />
        <meta name="distribution" content="Global" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
        <meta name="keywords" content="eDoctor, Sức khoẻ, Nhà thuốc, theo dõi sức khỏe, cải thiện sức khỏe" />

        <link rel={'manifest'} href={'/manifest.json'} />
        <link rel="apple-touch-icon" href="/assets/icons/icon_2x.png"></link>
        <link rel="icon" href="/favicon.ico" type="image/gif" sizes="16x16" />

        <link rel="preconnect" href={host.cmsApi} crossOrigin="" />
        <link rel="preconnect" href={host.uploadDomain} crossOrigin="" />
        <link rel="dns-prefetch" href={host.cmsApi} />
        <link rel="dns-prefetch" href={host.uploadDomain} />

        {/* FONTS */}
        <link as={'font'} type="font/ttf" rel={'preload'} href={'/fonts/Montserrat-Bold.ttf'} crossOrigin="" />
        <link as={'font'} type="font/ttf" rel={'preload'} href={'/fonts/Montserrat-Regular.ttf'} crossOrigin="" />
        <link as={'font'} type="font/ttf" rel={'preload'} href={'/fonts/Montserrat-Semibold.ttf'} crossOrigin="" />

        {/* ANIMATE CSS */}
        <link as={'style'} rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

        {/* JSON LD */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.jsonLD.website) }} />

        {/* OPEN GRAPH */}
        <meta property="fb:app_id" content={analytics.fbAppId} />

        {/* Lazyloaded Scripts */}
        <LazyloadScripts />
      </Head>

      <style jsx global>{`
        @font-face {
          font-display: swap;
          font-family: 'Montserrat';
          src: url('/fonts/Montserrat-Regular.ttf') format('opentype');
        }

        @font-face {
          font-weight: 500;
          font-display: swap;
          font-family: 'Montserrat';
          src: url('/fonts/Montserrat-Regular.ttf') format('opentype');
        }

        @font-face {
          font-weight: 600;
          font-display: swap;
          font-family: 'Montserrat';
          src: url('/fonts/Montserrat-Semibold.ttf') format('opentype');
        }

        @font-face {
          font-weight: bold;
          font-display: swap;
          font-family: 'Montserrat';
          src: url('/fonts/Montserrat-Bold.ttf') format('opentype');
        }

        html {
          box-sizing: border-box;
          font-size: 14px;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }

        ol,
        ul {
          list-style: none;
        }

        body {
          font-size: 14px;
          color: #000000;
          font-family: 'Montserrat', sans-serif !important;
          font-display: optional;
        }
      `}</style>

      <CorporateContactJsonLd {...seo.jsonLD.contact} />
      <NextSeo {...seo.basic(pathname, pageProps)} key={pathname} />

      <Alert>
        <ViewDetected deviceType={deviceType}>
          <Layout disableFooter={disableFooter} disableHeader={disableHeader} pathname={pathname}>
            <Component {...pageProps} />
          </Layout>
        </ViewDetected>
      </Alert>
    </>
  );
}

const handleUTMTracking = (appContext: AppContext) => {
  const query = appContext.ctx.query;
  const trackingKeys = [
    'traffic_id',
    'aff_sid',
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_campaign',
    'source',
    'extraData',
    'internal_channel',
    'url_referral',
    'channel',
    'referral',
  ];
  for (const key in query) {
    if (trackingKeys.includes(key)) {
      appContext.ctx.res.setHeader(
        'Set-Cookie',
        Cookie.serialize(key, query[key].toString(), {
          maxAge: 60 * 60 * 24 * 7, // 1 week,
          domain: host.rootDomain,
        }),
      );
    }
  }
};

const addIsAppCookies = (appContext: AppContext) => {
  if (appContext?.ctx?.query?.isApp === 'true') {
    appContext.ctx.res.setHeader(
      'Set-Cookie',
      Cookie.serialize('isApp', 'true', {
        maxAge: 60 * 60 * 24 * 365, // 1 years,
      }),
    );
  }
};

const getCookieToken = (appContext: AppContext) => {
  const cookies = Cookie.parse(appContext.ctx?.req?.headers?.cookie || '');
  return cookies.token || null;
};

const getCookieIsApp = (appContext: AppContext) => {
  const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
  const deviceType = getDeviceType(userDetected);
  const cookies = Cookie.parse(appContext.ctx.req.headers.cookie || '');
  return (cookies.isApp === 'true' || appContext?.ctx?.query?.isApp === 'true') && deviceType !== 'desktop';
};

const getLayoutConfiguration = (appContext: AppContext) => {
  const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
  const deviceType = getDeviceType(userDetected);

  let disableFooter = false;
  let disableHeader = false;

  if (['mobile', 'tablet'].includes(deviceType)) {
    disableFooter = false;
  }
  const cookies = Cookie.parse(appContext.ctx.req.headers.cookie || '');
  if (deviceType === 'mobile' && (appContext?.ctx?.query?.isApp === 'true' || cookies.isApp === 'true')) {
    disableFooter = true;
    disableHeader = true;
  }

  return {
    disableFooter,
    disableHeader,
  };
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  handleUTMTracking(appContext);

  addIsAppCookies(appContext);

  const token = getCookieToken(appContext);
  const isApp = getCookieIsApp(appContext);

  const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
  const deviceType = getDeviceType(userDetected);

  const layoutConfiguration = getLayoutConfiguration(appContext);

  const pathname = appContext.ctx.asPath || appContext.ctx.pathname;
  return { ...appProps, ...layoutConfiguration, token, isApp, deviceType, pathname };
};

export default MyApp;

import React, { createContext, DependencyList, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

interface ResultValue {
    isTop?: boolean;
    isBottom?: boolean;
}

type ScrollHandler = (event: Event, result: ResultValue) => Promise<any> | void;
interface LayoutContextType {
    disableFooter: boolean;
    setDisableFooter: (state: boolean) => void;

    disableHeader: boolean;
    setDisableHeader: (state: boolean) => void;

    disableFooterAnswer: boolean;
    setDisableFooterAnswer: (state: boolean) => void;

    onScroll(
        handler: ScrollHandler,
        deps?: DependencyList,
        beforeHanlder?: ({ isTop, isBottom }: ResultValue) => void,
    ): void;
}

const initState: LayoutContextType = {
  disableFooter: false,
  setDisableFooter: () => null,

  disableHeader: false,
  setDisableHeader: () => null,

  disableFooterAnswer: false,
  setDisableFooterAnswer: () => null,

  onScroll: () => null,
};

export const LayoutContext = createContext(initState);

export const useLayoutContext = (disableFooter = false, disableHeader = false): LayoutContextType => {
  return {
    ...useContext(LayoutContext),
    ...(disableFooter && { disableFooter }),
    ...(disableHeader && { disableHeader }),
  };
};

interface ProviderProps {}

const offset = 5;

export const Provider: FunctionComponent<ProviderProps> = (props) => {
  const scrollTimeout = useRef(null);
  const [disableFooter, setDisableFooter] = useState<boolean>(false);
  const [disableHeader, setDisableHeader] = useState<boolean>(false);
  const [disableFooterAnswer, setDisableFooterAnswer] = useState<boolean>(false);
  const onScroll = (
    handler: ScrollHandler,
    deps: DependencyList = [],
    beforeHanlder?: ({ isTop, isBottom }: ResultValue) => void,
  ) => {
    const scrollHandler = (event: Event) => {
      if (beforeHanlder) {
        beforeHanlder({
          isTop: window.scrollY === 0,
          isBottom: window.scrollY + window.innerHeight >= document.body.scrollHeight - offset,
        });
      }

      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = setTimeout(async () => {
        const completedHandler = await handler(event, {
          isTop: window.scrollY === 0,
          isBottom: window.scrollY + window.innerHeight >= document.body.scrollHeight - offset,
        });

        if (completedHandler && typeof completedHandler === 'function') {
          completedHandler();
        }
      }, 300);
    };

    useEffect(() => {
      document.addEventListener('scroll', scrollHandler);

      return () => document.removeEventListener('scroll', scrollHandler);
    }, deps);
  };

  return (
    <LayoutContext.Provider
      value={{
        disableFooter,
        disableHeader,
        disableFooterAnswer,
        // void
        onScroll,
        setDisableFooter,
        setDisableHeader,
        setDisableFooterAnswer,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

export function withLayout<P>(WrappedComponent: FunctionComponent<P>): FunctionComponent<P> {
  return (props) => (
    <Provider>
      <WrappedComponent {...props} />
    </Provider>
  );
}

import React, { FunctionComponent } from 'react';

// Components

// Contexts
import { withAlert } from './Context';

// Styles

interface AccountProps { }

const Alert: FunctionComponent<AccountProps> = (props) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default withAlert(Alert);

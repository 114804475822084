import React, { FunctionComponent } from 'react';

// Components
import Footer from './Components/Footer';
import Header from './Components/Header';
import Loading from './Components/Loading';
// import ComponentLazy from '@Components/Utils/Lazy/Component';
// import Actions from './Components/Actions';

// Styles
import styles from './Layout.module.scss';

// Utilities
import classnames from 'classnames';

// Contexts
import { useLayoutContext, withLayout } from './Contexts';
import { useViewDetected } from '@Components/Utils/ViewDetected';

interface LayoutProps {
  pathname?: string;
  disableHeader?: boolean;
  disableFooter?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const { deviceType } = useViewDetected();
  const { disableFooter, disableHeader } = useLayoutContext(props.disableFooter, props.disableHeader);

  const className = classnames([
    styles.layout,
    styles[deviceType],
    {
      [styles.disable_header]: props.disableHeader,
    },
  ]);

  return (
    <div className={className}>
      <Loading />

      {!disableHeader && <Header pathname={props.pathname} />}

      {props.children}

      {!disableFooter && <Footer />}
    </div>
  );
};

Layout.defaultProps = {
  disableHeader: false,
  disableFooter: false,
};

export default withLayout<LayoutProps>(Layout);

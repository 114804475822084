import React, { FunctionComponent, useEffect, useState, useRef, RefObject, RefAttributes } from 'react';

// Components
import Image from '../../Image';

// Styles
import styles from './ImgLazy.module.scss';

// Utilities
import classnames from 'classnames';

// Constant

interface ImgLazyProps {
    src: string;
    errorsrc?: string;
    className?: string;
    placeholder?: string;
}

const ImgLazy: FunctionComponent<ImgLazyProps & React.ImgHTMLAttributes<HTMLImageElement> & RefAttributes<HTMLImageElement>> = React.memo(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  React.forwardRef((props, ref) => {
    const imgRef: RefObject<HTMLImageElement> = useRef(null);
    const [imgLoaded, setImgLoaded] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<any>(props.placeholder);

    const className = classnames([
      props.className,
      styles.imglazy,
      {
        [styles.loaded]: imgLoaded,
      },
    ]);

    const handleImageLoaded = () => {
      setImgLoaded(true);
    };

    useEffect(() => {
      let unmouted = false;
      let observer: IntersectionObserver;

      const handleLoadImage = () => {
        if (imgRef && imgSrc !== props.src) {
          try {
            observer = new IntersectionObserver(
              (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                  if (!unmouted && entry.isIntersecting) {
                    setImgSrc(props.src);
                    observer.unobserve(imgRef.current);
                  }
                });
              },
              {
                threshold: 0.1,
                rootMargin: '70%',
              },
            );

            observer.observe(imgRef.current);
          } catch (error) {
            setImgSrc(props.src);
          }
        }
      };

      if (document.readyState !== 'complete') {
        window.addEventListener('load', handleLoadImage);
      } else {
        handleLoadImage();
      }

      return () => {
        unmouted = true;
        window.removeEventListener('load', handleLoadImage);
      };
    }, [imgRef, props.src]);

    return <Image {...props} className={className} src={imgSrc} ref={imgRef} onLoad={handleImageLoaded} />;
  }),
);

ImgLazy.defaultProps = {
  placeholder: null,
};

export default ImgLazy;

import { CorporateContactJsonLdProps, NextSeoProps } from 'next-seo';

// Config
import host from '@Config/host';

const description =
    'Hiểu và tầm soát sức khoẻ chủ động. tư vấn và xét nghiệm tại nhà. Chat online hoặc nhận tư vấn miễn phí từ các Bác sĩ. Các tin tức hữu ích về sức khoẻ. Các thông tin về phòng ngừa bệnh và dịch bệnh';

const BASIC: NextSeoProps = {
  description,
  title: 'eDoctor: Know Your Health',
  openGraph: {
    description,
    type: 'website',
    title: 'eDoctor: Know Your Health',
    images: [
      {
        url: `${host.homeDomain}/assets/facebook_share_image.png`,
      },
    ],
  },
};

const CONTACT_JSON_LD: CorporateContactJsonLdProps = {
  url: host.homeDomain,
  logo: `${host.homeDomain}/assets/logo.png`,
  contactPoint: [
    {
      telephone: '19006115',
      contactType: 'support',
      areaServed: 'VN',
      availableLanguage: 'Viet Nam',
    },
  ],
};

const WEBSITE_JSON_LD = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'eDoctor',
  url: host.homeDomain,
};

export default {
  basic: (pathname: string, pageProps?: any): NextSeoProps => {
    const seo: NextSeoProps = { ...(pageProps?.seo || BASIC) };

    if (!seo.openGraph) {
      seo.openGraph = {};
    }

    seo.openGraph.url = `${host.domain}${pathname}`;

    return seo;
  },
  jsonLD: {
    website: WEBSITE_JSON_LD,
    contact: CONTACT_JSON_LD,
  },
};

/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
import { FunctionComponent, useEffect } from 'react';

// Config
import analytics from '@Config/analytics';

// Utilities
import { useRouter } from 'next/router';
import { supportClass } from '@Config/tawk';

declare global {
  const gtag: (...args: any[]) => void;
}

declare global {
  interface Window {
    dataLayer: [];
    Tawk_API: any;
    filumanalytics: any;
    gtag: (...args: any[]) => void;
  }
}

interface ScriptLazyProps {}

const lzls = (s, f, o) => {
  const n = document.createElement('script');
  const k = Object.keys(o);

  for (let i = 0; i < k.length; i += 1) n[k[i]] = o[k[i]];
  f && (n.onload = f);
  document.head.appendChild(n);
  n.src = s;
};

const dolzli = (e, lzl, zlz, pzlz, o) => {
  if (e.dataset) {
    if (pzlz == null) {
      pzlz = [];
      const _pzlz = [zlz || 'src'];
      for (let i = 0; i < _pzlz.length; i++) {
        if (e.dataset[_pzlz[i]]) {
          pzlz.push(e.dataset[_pzlz[i]]);
        }
      }
    }
    if (pzlz.length === 0) return;

    e.onerror = function () {
      e.onerror = null;
      if (pzlz && pzlz.length) {
        pzlz.splice(0, 1);
        dolzli(e, lzl, zlz, pzlz, o);
      } else {
        e.src = '/assets/default_img.png';
      }
    };
    e.onload = function () {
      e.classList.remove(lzl);
    };
    if (pzlz[0]) e.src = pzlz[0];
  }
  'IntersectionObserver' in window && o && o.unobserve(e);
};

const lzli = (lzc, lzs, lzm, lzt) => {
  return function () {
    let observer;
    const options = {
      rootMargin: lzm || '0px',
      threshold: lzt != null ? lzt : 0.05,
    };
    const alzl = document.querySelectorAll('.' + lzc);

    function lzlc(e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i].intersectionRatio > 0) dolzli(e[i].target, lzc, lzs, null, observer);
      }
    }

    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(lzlc, options);
      for (let i = 0; i < alzl.length; i++) observer.observe(alzl[i]);
    } else {
      for (let i = 0; i < alzl.length; i++) dolzli(alzl[i], lzc, lzs, null, observer);
    }
  };
};

const getLoadGtagScript = function (gaTagID) {
  window.dataLayer = window.dataLayer || [];

  window.gtag = function () {
    // eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
  gtag('js', new Date());
  gtag('config', gaTagID, { send_page_view: false });

  return function () {
    lzls(`https://www.googletagmanager.com/gtag/js?id=${gaTagID}`, null, { async: true });
  };
};

const getLoadTawkScript = function (tawkToKey, className) {
  // eslint-disable-next-line prefer-const
  window.Tawk_API = window.Tawk_API || {};
  // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
  let Tawk_LoadStart = new Date();

  window.Tawk_API.onStatusChange = function () {
    document.addEventListener('click', (event: any) => {
      if (event.target?.classList?.contains(className)) {
        window.Tawk_API.toggle();
      }
    });
  };

  return function () {
    lzls(`https://embed.tawk.to/${tawkToKey}`, null, { async: true, charset: 'UTF-8', crossorigin: '*' });
  };
};

// const getLoadClarityScript = function (clarityTagID) {
//     return function () {
//         (function (c, l, a, r, i, t, y) {
//             c[a] =
//                 c[a] ||
//                 function () {
//                     // eslint-disable-next-line
//                     // @ts-ignore: Unreachable code error
//                     // eslint-disable-next-line prefer-rest-params
//                     (c[a].q = c[a].q || []).push(arguments);
//                 };
//             t = l.createElement(r);
//             t.async = 1;
//             t.src = 'https://www.clarity.ms/tag/' + i;
//             y = l.getElementsByTagName(r)[0];
//             y.parentNode.insertBefore(t, y);
//         })(window, document, 'clarity', 'script', clarityTagID);
//     };
// };

const getLoadBranchScript = function (branchKey) {
  return function () {
    (function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length;) c(h, _[s++]);
        d = r.createElement(a);
        d.async = 1;
        d.src = 'https://cdn.branch.io/branch-latest.min.js';
        k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h;
      }
    })(
      window,
      document,
      'script',
      'branch',
      function (b, r) {
        b[r] = function () {
          // eslint-disable-next-line
          // @ts-ignore: Unreachable code error
          // eslint-disable-next-line prefer-rest-params
          b._q.push([r, arguments]);
        };
      },
      { _q: [], _v: 1 },
      'addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode'.split(
        ' ',
      ),
      0,
    );
    branch.init(branchKey);
  };
};

const getLoadFilumScript = function (filumKey) {
  return function () {
    const e = window.filumanalytics || [];
    // eslint-disable-next-line no-var, prefer-rest-params, quotes
    // eslint-disable-next-line
    if (e.length === 0) {
      e.methods = ['load', 'track', 'identify', 'ready', 'reset', 'getAnonymousId', 'setAnonymousId'];
      e.factory = function (t) {
        return function () {
          // eslint-disable-next-line no-var, prefer-rest-params
          var r = Array.prototype.slice.call(arguments);
          return r.unshift(t), e.push(r), e;
        };
      };
      for (let t = 0; t < e.methods.length; t++) {
        const r = e.methods[t];
        e[r] = e.factory(r);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e.loadJS = function (e, t) {
        const r = document.createElement('script');
        r.type = 'text/javascript';
        r.async = !0;
        r.src = 'https://filum-assets.sgp1.digitaloceanspaces.com/sdk/v0.0.1-alpha/filum-analytics.min.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(r, s);
      };
      e.loadJS(), e.load(filumKey, 'https://event.filum.ai/events'), (window.filumanalytics = e);
    }
    window.onload = function () {
      window.filumanalytics.track('Page Loaded', {
        host: window.location.host,
        path: window.location.pathname,
        search: window.location.search,
      });
      for (let o = document.getElementsByTagName('a'), n = 0; n < o.length; n++) {
        o[n].onclick = function (o) {
          const n: any = o.target;
          // eslint-disable-next-line no-console
          console.log(n.innerText);
          window.filumanalytics.track('Link Clicked', {
            host: window.location.host,
            path: window.location.pathname,
            search: window.location.search,
            href: n.href,
            text: n.innerText.trim(),
          });
        };
      }

      const t = document.getElementsByTagName('button');
      for (let n = 0; n < t.length; n++) {
        t[n].onclick = function (o) {
          const n: any = o.target;
          // eslint-disable-next-line no-console
          console.log(n.innerText);
          window.filumanalytics.track('Button clicked', {
            host: window.location.host,
            path: window.location.pathname,
            search: window.location.search,
            text: n.innerText.trim(),
          });
        };
      }
    };
  };
};

const ScriptLazy: FunctionComponent<ScriptLazyProps> = () => {
  const lazyloadScript = `
        const lzli = ${lzli.toString()};
        const lzls = ${lzls.toString()};
        const dolzli = ${dolzli.toString()};

        try {
            const handleLoadFilumScript = ${getLoadFilumScript.toString()}("${analytics.filumKey}");
            const handleLoadGtagScript = ${getLoadGtagScript.toString()}("${analytics.gaMeasureID}");
            const handleLoadBranchScript = ${getLoadBranchScript.toString()}("${analytics.branchKey}")
            const handleLoadTawkScript = ${getLoadTawkScript.toString()}("${analytics.tawkToKey}", "${supportClass}");

            handleLoadGtagScript();
            handleLoadTawkScript();
            handleLoadFilumScript();
            handleLoadBranchScript();

            document.addEventListener('DOMContentLoaded', function (e) {
                lzli('lzi')(e);
            });
        } catch (error) {
            console.error("lazyloadScript", error)
        }

    `;

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: lazyloadScript,
      }}
    />
  );
};

export default ScriptLazy;

interface GAPageViewProps {
  title?: string;
  path?: string;
  sendTo?: string;
  location?: string;
}

export const PageView: FunctionComponent<GAPageViewProps> = ({ title, path, sendTo, location }) => {
  const { asPath } = useRouter();

  useEffect(() => {
    const defaultPath = path || asPath;
    const defaultTitle = title || document.title;

    try {
      gtag('set', 'page', defaultPath);

      gtag('event', 'page_view', {
        ...(sendTo && { send_to: sendTo }),
        ...(location && { page_location: location }),
        ...(defaultPath && { page_path: defaultPath }),
        ...(defaultTitle && { page_title: defaultTitle }),
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('PageView tracking error: ', error);
      }
    }
  }, [asPath, title, path, sendTo, location]);

  return null;
};

export const viewPage = ({ title, path, sendTo, location }: { title?: string; path?: string; sendTo?: string; location?: string }): void => {
  try {
    gtag('event', 'page_view', {
      ...(path && { page_path: path }),
      ...(sendTo && { send_to: sendTo }),
      ...(title && { page_title: title }),
      ...(location && { page_location: location }),
    });
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('PageView tracking error: ', error);
    }
  }
};

export const trackingEvent = (action: string, event_category: string, event_label = ''): void => {
  try {
    gtag('event', action, { event_category, event_label });
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('trackingEvent  error: ', error);
    }
  }
};

PageView.defaultProps = {};
